// import login css
@import "../../../../assets/css/pages/login/login-6.css";

kt-auth,
.kt-login {
  height: 100%;

  .mat-mdc-form-field {
    width: 100%;
  }

  // add extra right padding when displaying spinner in button
  .kt-spinner {
    padding-right: 3rem !important;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  &__desc {
    text-align: center;
  }
}

.logo-img {
  width: 100%;
}
